import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CALC_CART_SUB_TOTAL,
  INCREASE_CART_ITEM,
  DECREASE_CART_ITEM,
  REMOVE_CART_ITEM,
  deleteCartItem,
  getCart,
  checkoutCart,
  selectCartSubTotal,
  increaseCartItems,
  decreaseCartItems,
  GET_CART,
  CALC_CART_ITEMS,
  SET_CART_QUANTITY,
  setNewPrice,
  SET_CART_PRICE,
  getCustomers,
} from "../../redux/features/cart/cartSlice";
import Loader from "../loader/Loader";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "antd";
import deleteIcon from "../../assets/home/delete-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./cartDetail.css";
import {
  selectLoggedInBusinessOwner,
  selectUser,
} from "../../redux/features/auth/authSlice";

const initialState = { name: "", phone: "", email: "" };

export default function CartDetails({ isLoading, cart, user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartSubTotal = useSelector(selectCartSubTotal);
  const [customerInfo, setCustomerInfo] = useState(initialState);
  const admin = useSelector(selectLoggedInBusinessOwner);
  const currentUser = useSelector(selectUser);
  const [productQuantity, setProductQuantity] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Payment state
  const [paymentType, setPaymentType] = useState("");
  const [partPaymentDetails, setPartPaymentDetails] = useState({
    amountPaid: "",
    balance: cartSubTotal,
    paymentParts: [
      {
        amountPaid: "",
        datePaid: new Date().toISOString()
      }
    ]
  });

  const handleCustomerInfo = (e) => {
    const { name, value } = e.target;
    setCustomerInfo({ ...customerInfo, [name]: value });

    if (name === "name" && value) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const fetchSuggestions = async (name) => {
    try {
      const response = await dispatch(getCustomers());
      const suggestions = response.payload.filter((suggestion) => {
        return suggestion.name.toLowerCase().includes(name.toLowerCase());
      });
      setSuggestions(suggestions);
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setCustomerInfo(suggestion);
    setSuggestions([]);
    setShowSuggestions(false);
  };

  useEffect(() => {
    dispatch(CALC_CART_SUB_TOTAL(cart));
    // dispatch(getCart());
  }, [dispatch, cart]);

  const handleIncreament = async (id, cartId, q) => {
    const quantity = Number(q) + 1;
    let cartItem = cart?.items.find((item) => item._id === cartId);
    // update cartItem.quantity with quantity before dispatching INCREASE_CART_ITEM
    cartItem = { ...cartItem, quantity };
    await dispatch(INCREASE_CART_ITEM(cartItem));
    await dispatch(increaseCartItems({ id, cartId, quantity }));
  };

  const handleChangeQuantity = async (e, id, cartId) => {
    const quantity = Number(e.target.value);
    let cartItem = cart?.items.find((item) => item._id === cartId);
    // update cartItem.quantity with quantity before dispatching INCREASE_CART_ITEM
    cartItem = { ...cartItem, quantity };
    setProductQuantity(quantity);
    await dispatch(SET_CART_QUANTITY(cartItem));
    await dispatch(increaseCartItems({ id, cartId, quantity }));
  };

  const handleChangePrice = async (e, id, cartId) => {
    const price = Number(e.target.value);
    let cartItem = cart?.items.find((item) => item._id === cartId);
    cartItem = { ...cartItem, price };
    setProductQuantity(price);
    await dispatch(SET_CART_PRICE(cartItem));
    await dispatch(setNewPrice({ id, cartId, price }));
  };

  const handleDecreament = async (id, cartId, q) => {
    const quantity = Number(q) - 1;
    let cartItem = cart.items.find((item) => item._id === cartId);
    // update cartItem.quantity with quantity before dispatching INCREASE_CART_ITEM
    cartItem = { ...cartItem, quantity };
    await dispatch(DECREASE_CART_ITEM(cartItem));
    await dispatch(decreaseCartItems({ id, cartId, quantity }));
  };

  const handleDelete = (item) => {
    // console.log("deleting", id);
    confirmAlert({
      title: "Remove this item",
      message: "Are you sure you want to remove this item?.",
      buttons: [
        {
          label: "Remove",
          onClick: async () => {
            await dispatch(REMOVE_CART_ITEM(item._id));
            await dispatch(deleteCartItem(item._id));
            // dispatch(getCart());
          },
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No');
        },
      ],
    });
  };

  // handle checkout
  const handleCheckOut = async () => {
    // setIsLoading(true);
    if (
      customerInfo.name === "" ||
      customerInfo.email === "" ||
      customerInfo.phone === ""
    ) {
      toast.error("Please enter customer's Informations");
      navigate("/cart");
    } else if (paymentType === "") {
      toast.error("Please select a payment type");
    } else if (paymentType === "part" && partPaymentDetails.amountPaid <= 0) {
      toast.error("Please enter the amount paid for part payment");
    } else {
      const resultAction = await dispatch(
        checkoutCart({
          items: cart.items,
          customer: customerInfo,
          user,
          paymentDetails: {
            paymentType: paymentType,
            paymentStatus: paymentType === "part" ? "pending" : "completed",
            paymentDetails: paymentType === "part" ? partPaymentDetails : {},
          },
        })
      );

      if (checkoutCart.fulfilled.match(resultAction)) {
        await dispatch(getCart());
        navigate("/inventory/sales-checkin-new");
      } else {
        toast.error(
          resultAction.payload || "Failed to checkout. Please try again."
        );
      }
    }
  };

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const formatNumbers = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  const handlePaymentChange = (e) => {
    setPaymentType(e.target.value);
  };

  const handlePartPaymentChange = (e) => {
    const { name, value } = e.target;
    const newPartPaymentDetails = {
      ...partPaymentDetails,
      [name]: Number(value),
    };
    newPartPaymentDetails.balance = cartSubTotal - newPartPaymentDetails.amountPaid;

    if (name === "amountPaid") {
      newPartPaymentDetails.paymentParts = [
        {
          amountPaid: Number(value),
          datePaid: new Date().toISOString()
        }
      ];
    }

    setPartPaymentDetails(newPartPaymentDetails);
  };


  // const handlePartPaymentChange = (e) => {
  //   const { name, value } = e.target;
  
  //   // Remove leading zero if present
  //   const sanitizedValue = value.replace(/^0+/, '');
  
  //   const newPartPaymentDetails = {
  //     ...partPaymentDetails,
  //     [name]: sanitizedValue ? Number(sanitizedValue) : '',
  //   };
  
  //   newPartPaymentDetails.balance = cartSubTotal - newPartPaymentDetails.amountPaid;
  
  //   if (name === "amountPaid") {
  //     newPartPaymentDetails.paymentParts = [
  //       {
  //         amountPaid: newPartPaymentDetails.amountPaid,
  //         datePaid: new Date().toISOString(),
  //       },
  //     ];
  //   }
  
  //   setPartPaymentDetails(newPartPaymentDetails);
  // };
  

  return (
    <>
      {admin || currentUser?.permissions?.sellProducts ? (
        <div className="cart-details-page">
          {isLoading && <Loader />}
          {!isLoading && cart?.items?.length === 0 ? (
            <div>
              <h1>Cart is empty</h1>
            </div>
          ) : (
            <div className="cart-body">
              <div className="cart-items">
                <div class="grid-container">
                  <div className="grid-item-header">
                    <div class="grid-item header">
                      <h1>Product Name</h1>
                    </div>
                    <div class="grid-item header">
                      <h1>Quantity</h1>
                    </div>
                    <div class="grid-item header">
                      <h1>Selling Price</h1>
                    </div>
                    <div class="grid-item header">
                      <h1>Total value</h1>
                    </div>
                    <div class="grid-item header">
                      <h1>
                        <h1>Actions</h1>
                      </h1>
                    </div>
                  </div>

                  {cart &&
                    cart.items &&
                    cart.items.map((item, index) => {
                      return (
                        <div className="item-body-container">
                          <div key={index} className="grid-item-body">
                            <div class="grid-item body cart_item_1">
                              <Tooltip title={item.name}>
                                {/* {shortenText(item.name, 16)} */}
                                {item.name}
                              </Tooltip>
                            </div>
                            <div class="grid-item body cart_item_2">
                              <div>
                                <button
                                  onClick={() => {
                                    handleDecreament(
                                      item.id,
                                      item._id,
                                      item.quantity
                                    );
                                  }}
                                >
                                  -
                                </button>
                                <input
                                  autoComplete="off"
                                  className="actions-quantity"
                                  onChange={(e) =>
                                    handleChangeQuantity(e, item.id, item._id)
                                  }
                                  value={item.quantity}
                                />
                                <button
                                  onClick={() => {
                                    handleIncreament(
                                      item.id,
                                      item._id,
                                      item.quantity
                                    );
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div class="grid-item body cart_item_3">
                              <input
                                autoComplete="new-password"
                                onChange={(e) =>
                                  handleChangePrice(e, item.id, item._id)
                                }
                                value={item.price}
                              />
                            </div>
                            <div class="grid-item body cart_item_4">
                              <span>
                                {formatter(item.price * item.quantity)}
                              </span>
                            </div>
                            <div class="grid-item body cart_item_5">
                              <button onClick={() => handleDelete(item)}>
                                <img src={deleteIcon} alt="delete" />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="cart-info">
                <div>
                  <h3>Cart Total</h3>
                  <h4>{formatter(cartSubTotal)}</h4>
                </div>
                <div>
                  <h3>VAT</h3>
                  <h4>NGN 0.00</h4>
                </div>
              </div>

              <div className="customer-info">
                <h1>Customer's information</h1>
                <div className="name_field_customer_info">
                  <label>Name</label>
                  <input
                    autoComplete="new-name"
                    name="name"
                    value={customerInfo.name}
                    onChange={handleCustomerInfo}
                    type="text"
                    placeholder="Start typing..."
                    required
                  />
                  {showSuggestions && suggestions.length > 0 && (
                    <div className="suggestions-container">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="suggestion"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <label>Phone</label>
                  <input
                    name="phone"
                    value={customerInfo.phone}
                    onChange={handleCustomerInfo}
                    type="text"
                    placeholder="+23480621029"
                    required
                  />
                </div>

                <div>
                  <label>Email</label>
                  <input
                    name="email"
                    value={customerInfo.email}
                    onChange={handleCustomerInfo}
                    type="email"
                    placeholder="example@gmail.com"
                    required
                  />
                </div>

                <div>
                  <label>Payment Type</label>
                  <select
                    value={paymentType}
                    onChange={handlePaymentChange}
                    required
                  >
                    <option value="">Select Payment Type</option>
                    <option value="cash">Cash</option>
                    <option value="transfer">Transfer</option>
                    <option value="pos">POS</option>
                    <option value="part">Part Payment</option>
                  </select>
                </div>

                {paymentType === "part" && (
                  <div className="part-payment-details">
                    <div>
                      <label>Amount Paid</label>
                      <input
                        name="amountPaid"
                        value={partPaymentDetails.amountPaid}
                        onChange={handlePartPaymentChange}
                        type="number"
                        required
                      />
                    </div>
                    <div>
                      <label>Balance</label>
                      <input
                        name="balance"
                        value={partPaymentDetails.balance}
                        readOnly
                        type="number"
                      />
                    </div>
                  </div>
                )}

                <div className="check-out-actions">
                  <button onClick={() => handleCheckOut()}>Check Out</button>
                  <Link to="/inventory">
                    <button className="return-btn">Return to Ordering</button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="unauthorized-containers">
          <h1>Unauthorized</h1>
        </div>
      )}
    </>
  );
}
