import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIncompletePayments,
  selectIncompletePayments,
  updateIncompletePayment,
} from "../../redux/features/cart/cartSlice";
// import { SpinnerImg } from "../../loader/Loader";
import { IoIosSend } from "react-icons/io";
import "../../components/product/productList/ProductList";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Tooltip } from "antd";
import "../../components/product/salesList/salesList.css";
import arrowDown from "../../assets/home/arrowdown.svg";
import arrowUp from "../../assets/home/arrowUp.svg";
import { toast } from "react-toastify";
import FulfilmentHeader from "../../components/inventoryHeader/FulfilmentHeader";
import { useParams } from "react-router-dom";
import "./fulfilment.css"

const Fulfilment = ({ isLoading }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const incompletePayments = useSelector(selectIncompletePayments);
  const [amountPaid, setAmountPaid] = useState({});
  const [showBody, setShowBody] = useState({});
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(fetchIncompletePayments());
  }, [dispatch]);

  useEffect(() => {
    let filteredPayments = incompletePayments;
    if (id === "cleared") {
      filteredPayments = incompletePayments.filter(
        (item) => item?.payment?.paymentStatus === "completed"
      );
    } else {
      filteredPayments = incompletePayments.filter(
        (item) =>
          item?.payment?.paymentType === "part" &&
          item?.payment?.paymentStatus === "pending"
      );
    }

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredPayments.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredPayments.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, incompletePayments, id]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % currentItems.length;
    setItemOffset(newOffset);
  };

  const handleAmountChange = (id, value) => {
    setAmountPaid({
      ...amountPaid,
      [id]: value,
    });
  };

  const handleUpdatePayment = (id) => {
    const paymentUpdate = {
      id,
      amountPaid: Number(amountPaid[id]),
    };

    setAmountPaid((prevAmountPaid) => ({
      ...prevAmountPaid,
      [id]: "",
    }));

    if (paymentUpdate.amountPaid && paymentUpdate.amountPaid > 0) {
      dispatch(updateIncompletePayment(paymentUpdate)).then((result) => {
        if (updateIncompletePayment.fulfilled.match(result)) {
          toast.success("Payment updated successfully.");
          setAmountPaid((prevAmountPaid) => ({
            ...prevAmountPaid,
            [id]: "",
          }));
        } else {
          toast.error("Failed to update payment.");
        }
      });
    } else {
      toast.error("Please enter an amount.");
    }
  };

  const handleShowBody = (id) => {
    setShowBody((prev) => {
      const newState = { ...prev };
      newState[id] = !newState[id];
      return newState;
    });
  };

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };
 
  return (
    <>
      <div className="product-list fulfilment_page">
        <div className="table">
          <FulfilmentHeader
            search={search}
            handleSearchChange={(e) => setSearch(e.target.value)}
          />
          {/* {isLoading && <SpinnerImg />} */}

          <div className="">
            {!isLoading && incompletePayments.length === 0 ? (
              <p className="no-products-p">
                -- No incomplete payments found --
              </p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th>Amount Paid</th>
                    <th>Balance</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((item, index) => {
                    const { customer, payment, createdAt, _id, paymentStatus } =
                      item;
                    const formattedDate =
                      moment(createdAt).format("DD-MM-YYYY h:mmA");

                    return (
                      <>
                        <tr key={index}>
                          <td onClick={() => handleShowBody(_id)}>
                            {index + 1}
                          </td>
                          <td onClick={() => handleShowBody(_id)}>
                            {formattedDate}
                          </td>
                          <td onClick={() => handleShowBody(_id)}>
                            {customer.name}
                          </td>
                          <td onClick={() => handleShowBody(_id)}>
                            {formatter(payment?.paymentDetails?.amountPaid)}
                          </td>
                          <td onClick={() => handleShowBody(_id)}>
                            {formatter(payment?.paymentDetails?.balance)}
                          </td>
                          <td onClick={() => handleShowBody(_id)}>
                            {payment?.paymentStatus}
                          </td>
                          <td className="icons">
                            <span className="actions-spans">
                              <span
                                className="arrow-btn"
                                onClick={() => handleShowBody(_id)}
                              >
                                <img
                                  src={showBody[_id] ? arrowUp : arrowDown}
                                  alt="arrow down"
                                />
                              </span>
                              {payment?.paymentStatus !== "completed" && (
                                <span className="fufilment_field">
                                  <input
                                    type="number"
                                    value={amountPaid[_id] || ""}
                                    onChange={(e) =>
                                      handleAmountChange(_id, e.target.value)
                                    }
                                    placeholder="Enter amount"
                                  />
                                  <button
                                    onClick={() => handleUpdatePayment(_id)}
                                  >
                                    Update
                                  </button>
                                </span>
                              )}
                            </span>
                          </td>
                        </tr>
                        {showBody[_id] && (
                          <tr className="dynamically-display">
                            <td colSpan="7">
                              <div className="dynamic-content">
                                <div className="dynamically-display-header">
                                  <strong>Product Name</strong>
                                  <strong>Quantity</strong>
                                  <strong>Selling Price</strong>
                                </div>
                                {item.items.map((item, idx) => (
                                  <div
                                    key={idx}
                                    className="dynamically-display-body"
                                  >
                                    <span>{item.name}</span>
                                    <span>{item.quantity}</span>
                                    <span>{formatter(item.price)}</span>
                                  </div>
                                ))}
                                <div className="dynamically-display-header">
                                  <strong>Part Payments</strong>
                                </div>
                                {payment.paymentDetails.paymentParts.map(
                                  (part, idx) => (
                                    <div
                                      key={idx}
                                      className="dynamically-display-body"
                                    >
                                      <span>{formatter(part.amountPaid)}</span>
                                      <span>
                                        {moment(part.datePaid).format(
                                          "DD-MM-YYYY h:mmA"
                                        )}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="activePageClass"
          />
        </div>
      </div>
    </>
  );
};

export default Fulfilment;
