import React, { useState } from "react";
import show from "../../../assets/home/show.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { addSales, getBusiness } from "../../../services/authService";
import { toast } from "react-toastify";
import { SET_BUSINESS } from "../../../redux/features/auth/authSlice";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  permissions: {
    addProducts: false,
    deleteProducts: false,
    editProducts: false,
    grantPermissions: false,
    seeBusinessFinances: false,
    sellProducts: true,
  },
};

export default function AddSales({ handleCancel }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const { firstName, lastName, email, password } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // console.log("formData", formData);

  const handlePermissionChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      permissions: { ...formData.permissions, [name]: checked },
    });
  };

  const addNewSalesRep = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const userData = {
        ...formData,
      };

      const data = await addSales(userData);
      navigate("/accounts/sales");
      if(data.message) {
        toast.error(data.message);
        setIsLoading(false);
        return;
      }
      const businessData = await getBusiness();
      await dispatch(SET_BUSINESS(businessData));
      toast.success("Sales rep added successfully!")
      handleCancel();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-new-sales">
      {isLoading && <Loader />} 
      <div className="new-sales-data">
        <form onSubmit={(e) => addNewSalesRep(e)}>
          <div className="sales-details">
            <h1>Add New Salesperson</h1>
            <div className="form-field">
              <div>
                <label>Sales First Name*</label>
                <input
                  type="text"
                  placeholder="John"
                  required
                  name="firstName"
                  value={firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>Sales Last Name*</label>
                <input
                  type="text"
                  placeholder="Doe"
                  required
                  name="lastName"
                  value={lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-field">
              <div>
                <label>Sales Email*</label>
                <input
                  type="email"
                  placeholder="sales@gmail.com"
                  required
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="password-input">
                <label>Sales Password*</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  required
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-password"
                >
                  {!showPassword && <div className="cross-line"></div>}
                  <img src={show} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="permissions">
            <h3>Permissions</h3>
            <div className="permissions-boxes">
              <label>
                <input
                  type="checkbox"
                  name="addProducts"
                  checked={formData.permissions.addProducts}
                  onChange={handlePermissionChange}
                />
                Add Products
              </label>
              <label>
                <input
                  type="checkbox"
                  name="deleteProducts"
                  checked={formData.permissions.deleteProducts}
                  onChange={handlePermissionChange}
                />
                Delete products
              </label>
              <label>
                <input
                  type="checkbox"
                  name="editProducts"
                  checked={formData.permissions.editProducts}
                  onChange={handlePermissionChange}
                />
                Edit products
              </label>
              <label>
                <input
                  type="checkbox"
                  name="grantPermissions"
                  checked={formData.permissions.grantPermissions}
                  onChange={handlePermissionChange}
                />
                Grant permissions
              </label>
              <label>
                <input
                  type="checkbox"
                  name="seeBusinessFinances"
                  checked={formData.permissions.seeBusinessFinances}
                  onChange={handlePermissionChange}
                />
                See Business Finances
              </label>
              <label>
                <input
                  type="checkbox"
                  name="sellProducts"
                  checked={formData.permissions.sellProducts}
                  onChange={handlePermissionChange}
                />
                Sell products
              </label>
            </div>
          </div>
          <div className="submit-buttons">
            <button type="submit" className="add">
              Add
            </button>
            <button type="button" onClick={handleCancel} className="cancel">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
