import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Search from "../search/Search";

export default function FulfilmentHeader({search, handleSearchChange}) {
//   const [search, setSearch] = useState("");
  const [activeRoute, setActiveRoute] = useState("");

  const { id } = useParams(); 

  useEffect(() => {
    setActiveRoute(id)
  }, [id])

  return (
    <div className="--flex-between defaul-inventory-header">
      <span>
        <h3>
          <Link
            className={
              activeRoute === undefined ? "inventory-routes active-route" : "inventory-routes"
            }
            to="/fulfilments"
          >
            pending
          </Link>
          <Link
            className={
              activeRoute === "cleared" ? "inventory-routes active-route" : "inventory-routes"
            }
            to="/fulfilments/cleared"
          >
            Cleared
          </Link>
        </h3>
      </span>
      <span className="inventory_header_search">
        <Search value={search} onChange={handleSearchChange} />
      </span>
    </div>
  );
}
