import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cartService from "./cartService";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import fileDownload from "js-file-download";

// check if cart items is in local storage and set it to cart state
let isReceiptGenerated = false;

const initialState = {
  cart: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : {},
  isError: false,
  checkouts: [],
  isSuccess: false,
  isCartLoading: false,
  message: "",
  cartProfitValue: 0,
  cartSoldValue: 0,
  cartSubTotal: 0,
  totalCashCollected: 0,
  totalTransferCollected: 0,
  totalCollectedOnPOS: 0,
  pendingSalesValue: 0,
  pendingProfitValue: 0,
  cartItems: 0,
  incompletePayments: [],
};

// Add to cart
export const addToCart = createAsyncThunk(
  "product/addProductToCart",
  async ({ id, formData }, thunkAPI) => {
    try {
      return await cartService.addToCart(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get cart
export const getCart = createAsyncThunk(
  "products/getCart",
  async (_, thunkAPI) => {
    try {
      return await cartService.getCart();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCartItem = createAsyncThunk(
  "product/deleteCartItem",
  async (id, thunkAPI) => {
    try {
      return await cartService.deleteCartItem(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// increase cart items
export const increaseCartItems = createAsyncThunk(
  "product/increaseCartItems",
  async ({ id, cartId, quantity }, thunkAPI) => {
    try {
      return await cartService.increaseCartItems(id, cartId, quantity);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// set new price for item at sales
export const setNewPrice = createAsyncThunk(
  "product/setNewPrice",
  async ({ id, cartId, price }, thunkAPI) => {
    try {
      return await cartService.setPrice(id, cartId, price);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Set new quantity for product
export const setQuantity = createAsyncThunk(
  "product/setCatQuantity",
  async ({ id, cartId, quantity }, thunkAPI) => {
    try {
      return await cartService.setCartQuantity(id, cartId, quantity);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// decrease cart items
export const decreaseCartItems = createAsyncThunk(
  "product/decreaseCartItems",
  async ({ id, cartId, quantity }, thunkAPI) => {
    try {
      return await cartService.decreaseCartItems(id, cartId, quantity);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// checkout cart
export const checkoutCart = createAsyncThunk(
  "product/checkoutCart",
  async (formData, thunkAPI) => {
    try {
      return await cartService.checkoutCart(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get checkouts
export const getCheckouts = createAsyncThunk(
  "product/getCheckouts",
  async ({ start, end }, thunkAPI) => {
    try {
      if (start === "" || end === "") {
        return await cartService.getCheckouts(start, end);
      }
      return await cartService.getCheckouts(start, end);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// generate receipt
export const generateReceipt = createAsyncThunk(
  "product/generateReceipt",
  async (id, thunkAPI) => {
    try {
      const receipt = await cartService.generateReceipt(id);
      // if (!isReceiptGenerated) {
      const timestamp = Date.now();
      const randomNum = Math.floor(Math.random() * 1000);
      const receiptName = `receipt_${timestamp}_${randomNum}.pdf`;

      const pdfBlob = new Blob([receipt], { type: "application/pdf" });
      fileDownload(receipt, receiptName);

      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// send receipt to customer via whatsapp
export const sendReceipt = createAsyncThunk(
  "product/sendReceipt",
  async (id, thunkAPI) => {
    try {
      return await cartService.sendReceipt(id);
    } catch (error) {
      console.log("error", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// send receipt directly to printer for printing. such as thermal printer
export const printReceipt = createAsyncThunk(
  "product/sendReceiptToPrinterForPrinting",
  async (id, thunkAPI) => {
    try {
      return await cartService.printReceipt(id);
    } catch (error) {
      console.log("error", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const returnedGoods = createAsyncThunk(
  "cart/returnOfGoodsSoldOut",
  async ({ id, formData }, thunkAPI) => {
    try {
      return await cartService.returnedGoods(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCustomers = createAsyncThunk(
  "cart/getCustomers",
  async (_, thunkAPI) => {
    try {
      return await cartService.getCustomers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIncompletePayments = createAsyncThunk(
  "cart/cartPayments/fetchIncompletePayments",
  async (_, thunkAPI) => {
    try {
      return await cartService.getIncompletePayments();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateIncompletePayment = createAsyncThunk(
  "cart/cartPayments/updateIncompletePayment",
  async (formData, thunkAPI) => {
    try {
      return await cartService.updatePayment(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    CALC_CART_SUB_TOTAL(state, action) {
      const cart = action.payload;
      const array = [];
      if (cart && cart.items) {
        cart.items.map((item) => {
          const { price, quantity } = item;
          const cartValue = price * quantity;
          return array.push(cartValue);
        });
        const totalValue = array.reduce((a, b) => {
          return a + b;
        }, 0);
        state.cartSubTotal = totalValue;
      }
    },
    CALC_CART_ITEMS(state, action) {
      const cart = action.payload;
      if (cart && cart.items) {
        state.cartItems = cart.items.length;
      }
    },
    INCREASE_CART_ITEM(state, action) {
      let { id, cartId, quantity } = action.payload;
      let cart = state.cart;
      let item = cart.items.find((item) => item.id === id);
      let itemIndex = cart.items.findIndex((item) => item.id === id);
      item.quantity = quantity;
      // cart.items[itemIndex].quantity = item;
      // console.log("item quantity now", cart, item.quantity);
      localStorage.setItem("cartItems", JSON.stringify(cart));
      state.cart = cart;
    },
    SET_CART_QUANTITY(state, action) {
      let { id, cartId, quantity } = action.payload;
      let cart = state.cart;
      let item = cart.items.find((item) => item.id === id);
      let itemIndex = cart.items.findIndex((item) => item.id === id);
      item.quantity = quantity;
      // cart.items[itemIndex].quantity = item;
      // console.log("item quantity now", cart, item.quantity);
      localStorage.setItem("cartItems", JSON.stringify(cart));
      state.cart = cart;
    },
    SET_CART_PRICE(state, action) {
      let { id, cartId, price } = action.payload;
      let cart = state.cart;
      let item = cart.items.find((item) => item.id === id);
      let itemIndex = cart.items.findIndex((item) => item.id === id);
      item.price = price;
      // console.log("item quantity now", cart, item.quantity);
      localStorage.setItem("cartItems", JSON.stringify(cart));
      state.cart = cart;
    },
    GET_CART(state, action) {
      // state.cart = action.payload;
      // JASON.parse(localStorage.getItem("cartItems"));
      return JSON.parse(localStorage.getItem("cartItems"));
    },
    DECREASE_CART_ITEM(state, action) {
      let { id, cartId, quantity } = action.payload;
      let cart = state.cart;
      let item = cart.items.find((item) => item.id === id);
      let itemIndex = cart.items.findIndex((item) => item.id === id);
      item.quantity = quantity;
      // cart.items[itemIndex].quantity = item;
      // console.log("item quantity now", cart, item.quantity);
      localStorage.setItem("cartItems", JSON.stringify(cart));
      state.cart = cart;
    },
    REMOVE_CART_ITEM(state, action) {
      let { id, cartId } = action.payload;
      const updatedItems = state.cart.items.filter((item) => item.id !== id);

      // Update the state with the new array
      state.cart = {
        ...state.cart,
        items: updatedItems,
      };

      // Update local storage
      localStorage.setItem("cartItems", JSON.stringify(state.cart));
    },
    CALC_PROFIT_VALUE(state, action) {
      const carts = action.payload;
      console.log("carts", carts)
      const array = [];
      if (carts) {
        carts.forEach((cart) => {
          const { payment } = cart;
          console.log(payment.paymentStatus);
          if (
            !payment?.paymentType ||
            (payment && payment.paymentStatus === "completed")
          ) {
            cart.items.forEach((item) => {
              const { price, quantity, cost } = item;
              const cartValue = (price - cost) * quantity;
              array.push(cartValue);
            });
          }
        });
        const totalValue = array.reduce((a, b) => a + b, 0);
        state.cartProfitValue = totalValue;
      }
    },
    CALC_SOLD_VALUE(state, action) {
      const carts = action.payload;
      const array = [];
      if (carts) {
        carts.forEach((cart) => {
          const { payment } = cart;
          if (
            !payment?.paymentType ||
            (payment && payment.paymentStatus === "completed")
          ) {
            cart.items.forEach((item) => {
              const { price, quantity } = item;
              const cartValue = price * quantity;
              array.push(cartValue);
            });
          }
        });
        const totalValue = array.reduce((a, b) => a + b, 0);
        state.cartSoldValue = totalValue;
      }
    },
    CALC_SALES_BY_CASH(state, action) {
      const carts = action.payload;

      const filteredItems = [];
      carts.forEach((cart) => {
        const { payment, items } = cart;
        if (payment && payment.paymentType === "cash") {
          if (items && items.length > 0) {
            items.forEach((item) => {
              filteredItems.push(item);
            });
          }
        }
      });

      console.log('Filtered Items:', filteredItems);

      const array = [];
      filteredItems.forEach((item) => {
        const { price, quantity } = item;
        console.log('Item:', item);
        if (price && quantity) {
          const cartValue = price * quantity;
          array.push(cartValue);
        }
      });

      const totalValue = array.reduce((a, b) => a + b, 0);
      state.totalCashCollected = totalValue;
      console.log('Total Cash Collected:', totalValue);
    },
    CALC_SALES_BY_TRANSFER(state, action) {
      const carts = action.payload;

      const filteredItems = [];
      carts.forEach((cart) => {
        const { payment, items } = cart;
        if (payment && payment.paymentType === "transfer") {
          if (items && items.length > 0) {
            items.forEach((item) => {
              filteredItems.push(item);
            });
          }
        }
      });

      console.log('Filtered Items:', filteredItems);

      const array = [];
      filteredItems.forEach((item) => {
        const { price, quantity } = item;
        console.log('Item:', item);
        if (price && quantity) {
          const cartValue = price * quantity;
          array.push(cartValue);
        }
      });

      const totalValue = array.reduce((a, b) => a + b, 0);
      state.totalTransferCollected = totalValue;
      // console.log('Total Cash Collected:', totalValue);
    },
    CALC_SALES_BY_POS(state, action) {
      const carts = action.payload;

      const filteredItems = [];
      carts.forEach((cart) => {
        const { payment, items } = cart;
        if (payment && payment.paymentType === "pos") {
          if (items && items.length > 0) {
            items.forEach((item) => {
              filteredItems.push(item);
            });
          }
        }
      });

      console.log('Filtered Items:', filteredItems);

      const array = [];
      filteredItems.forEach((item) => {
        const { price, quantity } = item;
        console.log('Item:', item);
        if (price && quantity) {
          const cartValue = price * quantity;
          array.push(cartValue);
        }
      });

      const totalValue = array.reduce((a, b) => a + b, 0);
      state.totalCollectedOnPOS = totalValue;
      console.log('Total Cash Collected:', totalValue);
    },
    CALC_SALES_BY_PART_PENDING(state, action) {
      const carts = action.payload;
      const array = [];
      if (carts) {
        carts.forEach((cart) => {
          const { payment } = cart;
          if (payment && payment.paymentStatus === "pending") {
            cart.items.forEach((item) => {
              const { price, quantity } = item;
              const cartValue = price * quantity;
              array.push(cartValue);
            });
          }
        });
        const totalValue = array.reduce((a, b) => a + b, 0);
        state.pendingSalesValue = totalValue;
      }
    },
    CALC_PROFIT_BY_PART_PENDING(state, action) {
      const carts = action.payload;
      const array = [];
      if (carts) {
        carts.forEach((cart) => {
          const { payment } = cart;
          if (payment && payment.paymentStatus === "pending") {
            cart.items.forEach((item) => {
              const { price, quantity, cost } = item;
              const cartValue = (price - cost) * quantity;
              array.push(cartValue);
            });
          }
        });
        const totalValue = array.reduce((a, b) => a + b, 0);
        state.pendingProfitValue = totalValue;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        localStorage.setItem("cartItems", JSON.stringify(action.payload));
        toast.success("Product has been added to cart successfully!");
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getCart.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        localStorage.setItem("cartItems", JSON.stringify(action.payload));
        state.cart = action.payload;
      })
      .addCase(getCart.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(setNewPrice.pending, (state) => {
        state.isCartLoading = false;
      })
      .addCase(setNewPrice.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.cart = action.payload;
      })
      .addCase(setNewPrice.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(setQuantity.pending, (state) => {
        state.isCartLoading = false;
      })
      .addCase(setQuantity.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.cart = action.payload;
      })
      .addCase(setQuantity.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(increaseCartItems.pending, (state) => {
        state.isCartLoading = false;
      })
      .addCase(increaseCartItems.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.cart = action.payload;
      })
      .addCase(increaseCartItems.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(decreaseCartItems.pending, (state) => {
        state.isCartLoading = false;
      })
      .addCase(decreaseCartItems.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.cart = action.payload;
      })
      .addCase(decreaseCartItems.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(deleteCartItem.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(deleteCartItem.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        state.cart = action.payload;
        toast.success("item successfully deleted");
      })
      .addCase(deleteCartItem.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(checkoutCart.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(checkoutCart.fulfilled, (state, action) => {
        state.cart = action.payload.cart;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Checkout successfully");
        state.isCartLoading = false;
      })
      .addCase(checkoutCart.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getCheckouts.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(getCheckouts.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        state.checkouts = action.payload;
        // toast.success("Checkout successfully");
      })
      .addCase(getCheckouts.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(generateReceipt.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(generateReceipt.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Your receipt is ready!");
      })
      .addCase(generateReceipt.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(sendReceipt.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(sendReceipt.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Receipt sent successfully!");
      })
      .addCase(sendReceipt.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(printReceipt.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(printReceipt.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Your receipt is being printed");
      })
      .addCase(printReceipt.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(returnedGoods.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(returnedGoods.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        state.checkouts = action.payload;
        toast.success("returned items processed successfully");
      })
      .addCase(returnedGoods.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getCustomers.pending, (state) => {
        state.isCartLoading = false;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(fetchIncompletePayments.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(fetchIncompletePayments.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.incompletePayments = action.payload;
      })
      .addCase(fetchIncompletePayments.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(updateIncompletePayment.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(updateIncompletePayment.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.incompletePayments = action.payload;
      })
      .addCase(updateIncompletePayment.rejected, (state, action) => {
        state.isCartLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      });
  },
});

export const {
  CALC_CART_SUB_TOTAL,
  INCREASE_CART_ITEM,
  SET_CART_QUANTITY,
  SET_CART_PRICE,
  DECREASE_CART_ITEM,
  REMOVE_CART_ITEM,
  GET_CART,
  CALC_PROFIT_VALUE,
  CALC_SOLD_VALUE,
  CALC_CART_ITEMS,
  CALC_SALES_BY_CASH,
  CALC_SALES_BY_TRANSFER,
  CALC_SALES_BY_POS,
  CALC_SALES_BY_PART_PENDING,
  CALC_PROFIT_BY_PART_PENDING,
} = cartSlice.actions;

export const selectIsCartLoading = (state) => state.cart.isCartLoading;
export const selectCart = (state) => state.cart.cart;
export const selectCartCheckedOut = (state) => state.cart.checkouts;
export const selectCartSubTotal = (state) => state.cart.cartSubTotal;
export const selectCartProfitValue = (state) => state.cart.cartProfitValue;
export const selectCartSoldValue = (state) => state.cart.cartSoldValue;
export const selectCartItemsLength = (state) => state.cart.cartItems;
export const selectIncompletePayments = (state) => state.cart.incompletePayments;
export const selectTotalCash = (state) => state.cart.totalCashCollected;
export const selectTotalTransfer = (state) => state.cart.totalTransferCollected;
export const selectTotalPOS = (state) => state.cart.totalCollectedOnPOS;
export const selectPendingSalesValue = (state) => state.cart.pendingSalesValue;
export const selectPendingProfitValue = (state) => state.cart.pendingProfitValue;

export default cartSlice.reducer;
