import React from "react";

import "./ProductForm.scss";
import { useSelector } from "react-redux";
import {
  selectLoggedInBusinessOwner,
  selectUser,
} from "../../../redux/features/auth/authSlice";

const EditProductForm = ({ product, handleInputChange, saveProduct }) => {
  const admin = useSelector(selectLoggedInBusinessOwner);
  const currentUser = useSelector(selectUser);

  return (
    <>
      {admin || currentUser?.permissions?.editproducts ? (
        <div className="product-group">
          {/* {isLoading && <Loader />} */}
          <div className="product-form-group">
            <form onSubmit={saveProduct}>
              <div className="form-field">
                <div>
                  <label>Item name*</label>
                  <input
                    type="text"
                    name="name"
                    value={product?.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label>Category*</label>
                  <input
                    type="text"
                    name="category"
                    value={product?.category}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="form-field">
                <div>
                  <label>Cost Price*</label>
                  <input
                    type="text"
                    name="cost"
                    value={product?.cost}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label>Selling Price*</label>
                  <input
                    type="text"
                    name="price"
                    value={product?.price}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-field">
                <div>
                  <label>Quantity*</label>
                  <input
                    type="text"
                    name="quantity"
                    value={product?.quantity}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label>Warehouse*</label>
                  <input
                    type="text"
                    name="warehouse"
                    value={product?.warehouse}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="form-field single-field">
                <div>
                  <label>Description*</label>
                  <textarea
                    onChange={handleInputChange}
                    name="description"
                    value={product?.description}
                    cols="30"
                    rows="4"
                  ></textarea>
                  {/* <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  modules={ProductForm.modules}
                  // formats={ProductForm.formats}
                /> */}
                </div>
              </div>

              <div className="submit-product-group">
                <button type="submit">Update Products</button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="unauthorized-containers">
          <h1>Unauthorized</h1>
        </div>
      )}
    </>
  );
};

export default EditProductForm;
