import React, { useEffect, useState } from "react";
import "./ProductSummary.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CALC_CATEGORY,
  CALC_OUTOFSTOCK,
  CALC_STORE_VALUE,
  getLowProducts,
  getSalesByYear,
  getTopProducts,
  selectCategory,
  selectOutOfStock,
  selectTotalStoreValue,
} from "../../../redux/features/product/productSlice";
import {
  selectLoggedInBusinessOwner,
  selectUser,
} from "../../../redux/features/auth/authSlice";
import dash1 from "../../../assets/home/dash1.svg";
import dash2 from "../../../assets/home/dash2.svg";
import dash3 from "../../../assets/home/dash3.svg";
import dash4 from "../../../assets/home/dash4.svg";
import eyeIcon from "../../../assets/home/show.svg"; // Import your eye icon here
import OurChart from "./Chart";

// Format Amount
export const formatNumbers = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ProductSummary = ({ products, admin }) => {
  const dispatch = useDispatch();
  const totalStoreValue = useSelector(selectTotalStoreValue);
  const outOfStock = useSelector(selectOutOfStock);
  const category = useSelector(selectCategory);
  const { topProducts } = useSelector((state) => state.topProducts);
  const { lowProducts } = useSelector((state) => state.lowProducts);
  const { salesByYear } = useSelector((state) => state.salesByYear);
  const [year, setYear] = useState("2024");
  const [isStoreValueVisible, setIsStoreValueVisible] = useState(false);
  const currentUser = useSelector(selectUser);

  let filteredTopProducts = [];

  filteredTopProducts = topProducts.data?.filter((pr) => {
    return pr._id !== null;
  });

  let years = new Set(); // create an empty Set
  if (salesByYear.data) {
    for (var item of salesByYear.data) {
      // loop over the dataset
      years.add(item._id.year); // add the year of each item to the Set
    }
  }

  const uniqueYears = [...years]; // convert the Set to an array
  // console.log("Years unique", uniqueYears); // [2024, 2023, 2022

  const handleYearChange = (e) => {
    // console.log(e.target.value);
    setYear(e.target.value);
  };

  const toggleStoreValueVisibility = () => {
    setIsStoreValueVisible((prev) => !prev);
  };

  useEffect(() => {
    dispatch(CALC_STORE_VALUE(products));
    dispatch(CALC_OUTOFSTOCK(products));
    dispatch(getTopProducts());
    dispatch(getLowProducts());
    dispatch(CALC_CATEGORY(products));
    dispatch(getSalesByYear(year));
  }, [dispatch, products, year]);

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  return (
    <div className="store-summary items">
      <div className="item info_card total_products">
        <img src={dash1} alt="total products" />
        <h3>{products.length}</h3>
        <p>Total Products</p>
      </div>
      <div className="item info_card total_category">
        <img src={dash2} alt="total category" />
        <h3>{category.length}</h3>
        <p>Total Categories</p>
      </div>
      <div className="item sales_graph">
        <div className="chart-container-header">
          <h1>Sales & Profit</h1>
          <select onChange={handleYearChange}>
            {uniqueYears?.map((yr, index) => {
              return (
                <option key={index} value={yr}>
                  {yr}
                </option>
              );
            })}
          </select>
        </div>
        <div className="graph_container">
          {admin || currentUser?.permissions?.seeBusinessFinances ? (
            <OurChart sales={salesByYear} />
          ) : null}
        </div>
      </div>
      <div className="item top_selling">
        <div className="item-header">
          <h3>Top Selling Product</h3>
          <Link to="/">See All</Link>
        </div>
        <div className="item-body">
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Sold Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredTopProducts &&
                filteredTopProducts.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td> {index + 1} </td>
                      <td> {product?.sales[0]?.name}</td>
                      <td> {product?.total_sales}</td>
                      <td> {product?.sales[0]?.price}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="item info_card out_of_stock">
        <img src={dash3} alt="stock_out" />
        <h3>{outOfStock}</h3>
        <p>Out of stock Products</p>
      </div>
      <div className="item info_card store_value" onClick={toggleStoreValueVisibility}>
        <img src={dash4} alt="total products" />
        <h3>
          {admin || currentUser?.permissions?.seeBusinessFinances
            ? isStoreValueVisible
              ? `${formatter(totalStoreValue)}`
              : <img src={eyeIcon} alt="show value" />
            : "Unavailable"}
        </h3>
        <p>Total store value</p>
      </div>
      <div className="item low_products">
        <div className="item-header">
          <h3>Low quantity Products</h3>
          <Link to="/">See All</Link>
        </div>
        <div className="item-body">
          <table>
            <thead>
              <tr>
                <th>product</th>
                <th>Remaining Quantity</th>
              </tr>
            </thead>
            <tbody>
              {lowProducts &&
                lowProducts.data?.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td>{product.products[0].name}</td>
                      <td> {product.product_quantity} </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductSummary;
