import React, { useState } from "react";
import styles from "./auth.scss";
import { MdPassword } from "react-icons/md";
import Card from "../../components/card/Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword } from "../../services/authService";
import Loader from "../../components/loader/Loader";
import show from "../../assets/home/show.svg";

const initialState = {
  password: "",
  password2: "",
};

const Reset = () => {
  const [formData, setformData] = useState(initialState);
  const navigate = useNavigate();
  const { password, password2 } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  
  const queryParams = new URLSearchParams(window.location.search)
  const businessEmail = queryParams.get("businessEmail")
  const email = queryParams.get("email")
  console.log(email, businessEmail);

  const { resetToken } = useParams();
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const reset = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      return toast.error("Passwords must be up to 6 characters");
    }
    if (password !== password2) {
      return toast.error("Passwords do not match");
    }

    const userData = {
      password,
      password2,
    };

    setIsLoading(true);
    try {
      const data = await resetPassword(userData, resetToken, email, businessEmail);
      toast.success(data.message);
      setIsLoading(false);
      navigate("/login");
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  return (
    <div className="container auth">
      {isLoading && <Loader />}
      <div className="">
        <div className="auth-header">
          <h1>Set New Password</h1>
        </div>
        <form onSubmit={reset}>
          <div className="auth-inputs">
            <div className="input-field">
              <div className="password-input">
                <label>Create New Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  required
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-password"
                >
                  {!showPassword && <div className="cross-line"></div>}
                  <img src={show} alt="" />
                </div>
              </div>
            </div>
            <div className="input-field">
              <div className="password-input">
                <label>Confirm New Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  required
                  name="password2"
                  value={password2}
                  onChange={handleInputChange}
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-password"
                >
                  {!showPassword && <div className="cross-line"></div>}
                  <img src={show} alt="" />
                </div>
              </div>
            </div>
            <button type="submit" className="--btn --btn-primary --btn-block">
              Create Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Reset;
