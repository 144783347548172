import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Card from "../../card/Card";

import "./ProductForm.scss";
import { useSelector } from "react-redux";
import { selectLoggedInBusinessOwner, selectUser } from "../../../redux/features/auth/authSlice";

const ProductForm = ({
  product,
  description,
  setDescription,
  handleInputChange,
  saveProduct,
}) => {
  const admin = useSelector(selectLoggedInBusinessOwner);
  const currentUser = useSelector(selectUser);
 
  return (
    <>
      {admin || currentUser?.permissions?.addProducts ? (
        <>
          <div className="product-group">
            {/* {isLoading && <Loader />} */}
            <h1>Add a Single Item</h1>
            <div className="product-form-group">
              <form onSubmit={saveProduct}>

                <div className="form-field">
                  <div>
                    <label>Item name*</label>
                    <input
                      type="text"
                      name="name"
                      value={product?.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label>Category*</label>
                    <input
                      type="text"
                      name="category"
                      value={product?.category}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-field">
                  <div>
                    <label>Cost Price*</label>
                    <input
                      type="text"
                      name="cost"
                      value={product?.cost}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label>Selling Price*</label>
                    <input
                      type="text"
                      name="price"
                      value={product?.price}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-field">
                  <div>
                    <label>Quantity*</label>
                    <input
                      type="text"
                      name="quantity"
                      value={product?.quantity}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label>Warehouse*</label>
                    <input
                      type="text"
                      name="warehouse"
                      value={product?.warehouse}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-field single-field">
                  <div>
                    <label>Description*</label>
                    <textarea
                      onChange={(e) => setDescription(e.target.value)}
                      name="description"
                      value={description}
                      cols="30"
                      rows="4"
                    ></textarea>
                    {/* <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                modules={ProductForm.modules}
                // formats={ProductForm.formats}
              /> */}
                  </div>
                </div>

                <div className="submit-product-group">
                  <button type="submit">Add product</button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <div className="unauthorized-containers">
          <h1>Unauthorized</h1>
        </div>
      )}
    </>
  );


};

// ProductForm.modules = {
//   toolbar: [
//     [{ header: "1" }, { header: "2" }, { font: [] }],
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [{ align: [] }],
//     [{ color: [] }, { background: [] }],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ],
//     ["clean"],
//   ],
// };
// ProductForm.formats = [
//   "header",
//   "font",
//   "size",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "color",
//   "background",
//   "list",
//   "bullet",
//   "indent",
//   "link",
//   "video",
//   "image",
//   "code-block",
//   "align",
// ];

export default ProductForm;
