import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  createRef,
} from "react";
import plusadd from "../../assets/home/plusadd.svg";
import crox from "../../assets/home/crox.svg";
import { RxCrossCircled } from "react-icons/rx";
import deleteIcon from "../../assets/home/deleteIcon.svg";
import {
  getAllProductGroups,
  selectIsLoading,
  updateProductGroup,
} from "../../redux/features/product/productSlice";
import "./addproduct.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import {
  selectIsLoggedIn,
  selectLoggedInBusinessOwner,
  selectUser,
} from "../../redux/features/auth/authSlice";

const initialState = {
  groupName: "",
  category: "",
  description: "",
  cost: [],
  price: [],
  sku: [],
  warehouse: [],
  attributes: [],
  options: {},
  combinations: [],
};

export default function EditProductGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [attributes, setAttributes] = useState([""]);
  const [combinations, setCombinations] = useState([]);
  const isLoading = useSelector(selectIsLoading);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { allProductGroups } = useSelector((state) => state.allProductGroups);
  const [sku, setSku] = useState([]);
  const [price, setPrice] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [cost, setCost] = useState([]);
  const currentUser = useSelector(selectUser);
  const admin = useSelector(selectLoggedInBusinessOwner);

  const { id } = useParams();

  const generateKSKU = (comb) => {
    let part1 = comb.split("-");
    let part2 = part1[1].split("/");

    const letter = part1[0];
    const number = part2[0].slice(-4);
    const sku = letter + "- " + number;
    return sku;
  };

  // function removeFromCombined(array, combinationToRemove, indexToRemove) {
  //   if (combinationToRemove && indexToRemove >= 0) {
  //     array.forEach((optionGroup) => {
  //       const attrArray = optionGroup.attr;

  //       if (indexToRemove < attrArray.length) {
  //         attrArray.splice(indexToRemove+1, 1);
  //       }
  //     });

  //     return array;
  //   }
  //   return array;
  // }

  function removeFromCombined(array, combinationToRemove, indexToRemove) {
    // Create a deep copy of the array to avoid modifying the state directly
    const arrayCopy = JSON.parse(JSON.stringify(array));

    if (combinationToRemove && indexToRemove >= 0) {
      arrayCopy.forEach((optionGroup) => {
        const attrArray = optionGroup.attr;

        if (indexToRemove < attrArray.length) {
          attrArray.splice(indexToRemove + 1, 1);
        }
      });

      return arrayCopy;
    }
    return arrayCopy;
  }


  const initialOptions = attributes?.map(() => {
    return {
      attr: [{ value: "", showInput: true, attribute: "" }],
    };
  });
  const [options, setOptions] = useState({ ...initialOptions });

  const inputRefs = useRef([]);
  const focusInput = (ref) => {
    ref?.current?.focus();
  };

  function combineMultipleOptions(...optionsArrays) {
    // Determine the longest array length
    const maxLength = Math.max(
      ...optionsArrays.map((options) => options.length)
    );
    const combinedOptions = [];

    // Combine the options from all arrays
    for (let i = 0; i < maxLength; i++) {
      combinedOptions[i] = `${formData.groupName} - ${optionsArrays
        .map((options) => options[i] || "")
        .join("/")}`;
    }

    return combinedOptions;
  }

  const handleSetCombinations = () => {
    const updatedOptionsArrays = Object.values(options).map((option) => {
      if (option.attr) {
        return option.attr
          .map((opt) => opt.value)
          .filter((value) => value !== "");
      }
      return [];
    });

    const combinedOptions = combineMultipleOptions(...updatedOptionsArrays);

    setCombinations(combinedOptions);

    setFormData((prevFormData) => ({
      ...prevFormData,
      combinations: combinedOptions,
    }));
  };

  const handleKeyPress = (e, index, index2) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      if (e.target.value.trim() !== "") {
        const inputValue = e.target.value.trim();

        e.target.value = "";
        setOptions((prevOptions) => {
          const newOptions = JSON.parse(JSON.stringify(prevOptions));
          newOptions[index].attr = [
            ...newOptions[index].attr,
            { value: inputValue, showInput: false },
          ];

          const updatedOptionsArrays = Object.values(newOptions).map(
            (option) => {
              if (option.attr) {
                return option.attr
                  .map((opt) => opt.value)
                  .filter((value) => value !== "");
              }
              return [];
            }
          );

          const combinedOptions = combineMultipleOptions(
            ...updatedOptionsArrays
          );
          setCombinations(combinedOptions);

          setFormData((prevComb) => ({
            ...prevComb,
            combinations: [...combinedOptions],
            options: newOptions,
          }));
          return newOptions;
        });
      }
      // setCombinations(JSON.parse(localStorage.getItem("combinations")) || []);
      handleSetCombinations();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const removeItem = (index, index2) => {
    setOptions((prevOptions) => {
      const newOptions = JSON.parse(JSON.stringify(prevOptions));
      const updatedAttr = [...newOptions[index].attr];

      const removedValue = updatedAttr[index2].value;

      updatedAttr.splice(index2, 1);
      newOptions[index].attr = updatedAttr;

      const updatedOptionsArrays = Object.values(newOptions).map((option) => {
        if (option.attr) {
          return option.attr
            .map((opt) => opt.value)
            .filter((value) => value !== "");
        }
        return [];
      });
      const combinedOptions = combineMultipleOptions(...updatedOptionsArrays);
      setCombinations(combinedOptions);
      const updatedSku = sku.slice(0, combinedOptions.length);
      const updatedPrice = price.slice(0, combinedOptions.length);
      const updatedCost = cost.slice(0, combinedOptions.length);
      const updatedWareHouse = warehouse.slice(0, combinedOptions.length)

      setSku(updatedSku);
      setPrice(updatedPrice);
      setCost(updatedCost);
      setWarehouse(updatedWareHouse);

      setFormData((prevComb) => ({
        ...prevComb,
        combinations: combinedOptions,
        sku: updatedSku,
        cost: updatedCost,
        options: newOptions,
        price: updatedPrice,
        warehouse: updatedWareHouse,
      }));

      return newOptions;
    });
    handleSetCombinations();
  };

  const handleDeleteAttribute = (index) => {
    setCombinations(JSON.parse(localStorage.getItem("combinations")) || []);
    setAttributes((prevAttributes) => {
      const newAttributes = [...prevAttributes];
      newAttributes.splice(index, 1);
      setFormData((prevComb) => ({
        ...prevComb,
        attributes: newAttributes,
      }));
      return newAttributes;
    });
    setOptions((prevOptions) => {
      if (prevOptions) {
        const { [index]: removedOption, ...newOptions } = prevOptions;

        const updatedOptionsArrays = Object.values(newOptions).map((option) => {
          if (option.attr) {
            return option.attr
              .map((opt) => opt.value)
              .filter((value) => value !== "");
          }
          return [];
        });
        const combinedOptions = combineMultipleOptions(...updatedOptionsArrays);
        setCombinations(combinedOptions);
        setFormData((prevComb) => ({
          ...prevComb,
          combinations: [...combinedOptions],
          options: newOptions,
        }));
        return newOptions;
      }
    });
    handleSetCombinations();
  };

  const handleAddAttribute = () => {
    const updatedAttributes = [...attributes, ""];
    setAttributes(updatedAttributes);

    const newOptions = updatedAttributes.map((attr, index) => {
      return {
        attr:
          options[index] && options[index].attr.length > 0
            ? [...options[index].attr]
            : [{ value: "", showInput: true, attribute: attr }],
      };
    });

    const updatedOptionsArrays = Object.values(newOptions).map((option) => {
      if (option.attr) {
        return option.attr
          .map((opt) => opt.value)
          .filter((value) => value !== "");
      }
      return [];
    });
    const combinedOptions = combineMultipleOptions(...updatedOptionsArrays);
    setCombinations(combinedOptions);

    setFormData((prevComb) => ({
      ...prevComb,
      combinations: [...combinedOptions],
      options: newOptions,
    }));
    setOptions(newOptions);
    // handleSetCombinations();
  };

  const handleBlur = (e, index) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[index] = e.target.value;
    setAttributes(updatedAttributes);
    setFormData((prevComb) => ({
      ...prevComb,
      attributes: updatedAttributes,
    }));
    // setCombinationsUpdated(false);
  };

  const handleSku = (e, index) => {
    const updatedSku = [...sku];
    updatedSku[index] = e.target.value;
    setSku([...updatedSku]);
    setFormData((prevComb) => ({
      ...prevComb,
      sku: updatedSku,
    }));
  };

  // const handlePrice = (e, index) => {
  //   const updatedPrice = [...price];
  //   updatedPrice[index] = e.target.value;
  //   setPrice([...updatedPrice]);
  //   setFormData((prevComb) => ({
  //     ...prevComb,
  //     price: updatedPrice,
  //   }));
  //   // setCombinationsUpdated(false);
  // };

  const handlePrice = (e, index) => {
    const { value } = e.target;
    const regex = /^[0-9]*$/; // Only allow numbers
    if (regex.test(value)) {
      const updatedPrice = [...price];
      updatedPrice[index] = value;
      setPrice([...updatedPrice]);
      setFormData((prevComb) => ({
        ...prevComb,
        price: updatedPrice,
      }));
    } else {
      toast.error("Only numbers are allowed.");
      e.target = ""
    }
  };

  const handleWareHouse = (e, index) => {
    const updatedWareHouse = [...warehouse];
    updatedWareHouse[index] = e.target.value;
    setWarehouse([...updatedWareHouse]);
    setFormData((prevComb) => ({
      ...prevComb,
      warehouse: updatedWareHouse,
    }));
  }; 

  // const handleCost = (e, index) => {
  //   const updatedCost = [...cost];
  //   updatedCost[index] = e.target.value;
  //   setCost([...updatedCost]);
  //   setFormData((prevComb) => ({
  //     ...prevComb,
  //     cost: updatedCost,
  //   }));
  // };

  const handleCost = (e, index) => {
    const { value } = e.target;
    const regex = /^[0-9]*$/; // Only allow numbers
    if (regex.test(value)) {
      const updatedCost = [...cost];
      updatedCost[index] = value;
      setCost([...updatedCost]);
      setFormData((prevComb) => ({
        ...prevComb,
        cost: updatedCost,
      }));
    } else {
      toast.error("Only numbers are allowed.");
      e.target = ""
    }
  };

  const handleSetGroupName = (e, index) => {
    const updatedCombinations = [...combinations];
    updatedCombinations[index] = e.target.value;
    setCombinations([...updatedCombinations]);
    setFormData((prevComb) => ({
      ...prevComb,
      combinations: updatedCombinations,
    }));
  };

  const handleCopySkuToAll = () => {
    const updatedSku = [...sku];
    for (let i = 0; i < combinations.length; i++) {
      updatedSku[i] = generateKSKU(combinations[i]);
    }
    setFormData((prevForm) => ({
      ...prevForm,
      sku: [...updatedSku],
    }));
    setSku([...updatedSku]);
  };

  const handleCopyCostToAll = () => {
    const updatedCost = [...cost];
    for (let i = 0; i < combinations.length; i++) {
      updatedCost[i] = updatedCost[0];
    }

    setFormData((prevForm) => ({
      ...prevForm,
      cost: [...updatedCost],
    }));
    setCost([...updatedCost]);
  };

  const handleCopyPriceToAll = () => {
    const updatedPrice = [...price];
    for (let i = 0; i < combinations.length; i++) {
      updatedPrice[i] = updatedPrice[0];
    }

    setFormData((prevForm) => ({
      ...prevForm,
      price: [...updatedPrice],
    }));
    setPrice([...updatedPrice]);
  };

  const handleCopyWarehouseToAll = () => {
    const updatedWareHouse = [...warehouse];
    for (let i = 0; i < combinations.length; i++) {
      updatedWareHouse[i] = updatedWareHouse[0];
    }

    setFormData((prevForm) => ({
      ...prevForm,
      warehouse: [...updatedWareHouse],
    }));
    setWarehouse([...updatedWareHouse]);
  };

  const handleDeleteCombinations = (combination) => {
    // TODO: Decide whether to debug this or leave it untouched
    const index = combinations.indexOf(combination);
    const updatedOptions = removeFromCombined([...options], combination, index);

    setCombinations((prevCombinations) => {
      const newCombinations = prevCombinations.filter(
        (com) => com !== combination
      );

      const updatedSku = sku.slice(0, newCombinations.length);
      const updatedPrice = price.slice(0, newCombinations.length);
      const updatedCost = cost.slice(0, newCombinations.length);
      const updatedWareHouse = warehouse.slice(0, newCombinations.length);

      setSku(updatedSku);
      setPrice(updatedPrice);
      setCost(updatedCost);
      setWarehouse(updatedWareHouse);

      setFormData((prevComb) => ({
        ...prevComb,
        combinations: newCombinations,
        sku: updatedSku,
        cost: updatedCost,
        options: updatedOptions,
        price: updatedPrice,
        warehouse: updatedWareHouse,
      }));
      setOptions(updatedOptions);

      return newCombinations;
    });
  };

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getAllProductGroups());
    }
  }, [isLoggedIn, dispatch]);

  // console.log("formData", formData);
  // console.log("combinations", combinations);

  useEffect(() => {
    const productToEdit = allProductGroups?.find(
      (product) => product._id === id
    );
    handleSetCombinations();
    // alignCombinations();
    if (productToEdit) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        combinations: productToEdit.combinations,
        groupName: productToEdit.groupName,
        category: productToEdit.category,
        options: productToEdit.options,
        attributes: productToEdit.attributes,
        sku: productToEdit.sku,
        cost: productToEdit.cost,
        price: productToEdit.price,
        warehouse: productToEdit.warehouse,
        description: productToEdit.description,
      }));
      setAttributes(productToEdit.attributes);
      setSku(productToEdit.sku);
      setPrice(productToEdit.price);
      setCombinations(productToEdit.combinations);
      setCost(productToEdit.cost);
      setWarehouse(productToEdit.warehouse);
      setOptions(productToEdit.options);
    }
  }, [allProductGroups, id]);

  useEffect(() => {
    inputRefs.current = attributes.map(
      (_, i) => inputRefs.current[i] || createRef()
    );
  }, [attributes]);

  const saveProductGroup = async (e) => {
    if (
      formData.groupName.trim() === "" ||
      formData.category.trim() === "" ||
      formData.description.trim() === "" ||
      formData.cost.length === 0 ||
      formData.price.length === 0 ||
      formData.sku.length === 0 ||
      formData.warehouse.length === 0 ||
      formData.attributes.length === 0 ||
      Object.keys(formData.options).length === 0 ||
      formData.combinations.length === 0
    ) {
      toast.error("Please enter all fields");
      navigate(`/edit-product/group/${id}`);
      return;
    }

    await dispatch(updateProductGroup({ id, formData }));
    navigate("/inventory/product-groups");
  };

  return (
    <>
      {admin || currentUser?.permissions?.editproducts ? (
        <div className="product-group">
          {isLoading && <Loader />}
          <h1>Edit item group</h1>
          <div className="product-form-group">
            <div className="form-field">
              <div>
                <label>Item group name*</label>
                <input
                  value={formData?.groupName}
                  name="groupName"
                  onChange={handleInputChange}
                  type="text"
                />
              </div>
              <div>
                <label>Category*</label>
                <input
                  value={formData?.category}
                  name="category"
                  onChange={handleInputChange}
                  type="text"
                />
              </div>
            </div>
            <div className="form-field single-field">
              <div>
                <label>Description*</label>
                <textarea
                  onChange={handleInputChange}
                  name="description"
                  value={formData?.description}
                  id=""
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
            </div>

            {Array.isArray(attributes) &&
              attributes.map((attr, index) => {
                return (
                  <div
                    key={index}
                    className="form-field product_group_attributes"
                  >
                    <div>
                      <label>Attribute*</label>
                      <input
                        name={`attribute_${index}`} // Use a unique name for each attribute
                        value={attr}
                        onChange={(e) => handleBlur(e, index)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Options*</label>
                      <div
                        onClick={() => focusInput(inputRefs.current[index])}
                        className="options"
                      >
                        <ul>
                          {options[index] &&
                            options[index].attr.map((option, index2) => (
                              <li key={index2}>
                                {!option.showInput && (
                                  <span>
                                    {option.value}
                                    <img
                                      onClick={() => removeItem(index, index2)}
                                      src={crox}
                                      alt="cancel"
                                    />
                                  </span>
                                )}
                              </li>
                            ))}
                          {options[index] &&
                            options[index].attr.some(
                              (option) => option.showInput
                            ) && (
                              <input
                                ref={inputRefs.current[index]}
                                type="text"
                                onKeyDown={(e) => handleKeyPress(e, index)}
                                inputMode="text"
                                pattern="[A-Za-z0-9,]*"
                              />
                            )}
                        </ul>
                      </div>
                    </div>
                    <img
                      src={deleteIcon}
                      onClick={() => {
                        handleDeleteAttribute(index);
                      }}
                      alt="delete"
                    />
                  </div>
                );
              })}

            <span onClick={handleAddAttribute}>
              <img src={plusadd} alt="add" /> Add more attributes
            </span>
          </div>

          <div className="group-items-table">
            <table>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>
                    SKU <p onClick={handleCopySkuToAll}>Generate SKU</p>
                  </th>
                  <th>
                    Cost Price <p onClick={handleCopyCostToAll}>Copy to all</p>
                  </th>
                  <th>
                    Selling price{" "}
                    <p onClick={handleCopyPriceToAll}>Copy to all</p>
                  </th>
                  <th>
                    Warehouse{" "}
                    <p onClick={handleCopyWarehouseToAll}>Copy to all</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {combinations.length > 0 ? (
                  combinations.map((com, index) => {
                    return (
                      <tr key={index} className="individual_item_group">
                        <td className="group-item-name">
                          <textarea
                            onChange={(e) => handleSetGroupName(e, index)}
                            name="item-name"
                            // ref={inputRefs2.current[index]}
                            value={com}
                          ></textarea>
                          {/* <input
                            onChange={(e) => handleSetGroupName(e, index)}
                            name="item-name"
                            // ref={inputRefs2.current[index]}
                            value={com}
                            type="text"
                          /> */}
                        </td>
                        <td>
                          <textarea
                            onChange={(e) => handleSku(e, index)}
                            key={index}
                            name="sku"
                            value={sku[index]}
                          ></textarea>
                          {/* <input
                            onChange={(e) => handleSku(e, index)}
                            key={index}
                            name="sku"
                            value={sku[index]}
                            type="text"
                          /> */}
                        </td>
                        <td>
                          <textarea
                            onChange={(e) => handleCost(e, index)}
                            name="cost"
                            key={index}
                            // type="text"
                            value={cost[index]}
                          ></textarea>
                          {/* <input
                            onChange={(e) => handleCost(e, index)}
                            name="cost"
                            key={index}
                            type="text"
                            value={cost[index]}
                          /> */}
                        </td>
                        <td>
                          <textarea
                            onChange={(e) => handlePrice(e, index)}
                            name="price"
                            key={index}
                            // type="text"
                            value={price[index]}
                          ></textarea>
                          {/* <input
                            onChange={(e) => handlePrice(e, index)}
                            name="price"
                            key={index}
                            type="text"
                            value={price[index]}
                          /> */}
                        </td>
                        <td>
                          <textarea
                            onChange={(e) => handleWareHouse(e, index)}
                            name="warehouse"
                            key={index}
                            // type="text"
                            value={warehouse[index]}
                          ></textarea>
                          {/* <input
                            onChange={(e) => handlePrice(e, index)}
                            name="price"
                            key={index}
                            type="text"
                            value={price[index]}
                          /> */}
                        </td>
                        <RxCrossCircled
                          onClick={() => handleDeleteCombinations(com)}
                          className="delete-combinations"
                          size={16}
                        />
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>
                      <p>Enter an attribute...</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="submit-product-group">
            <button onClick={saveProductGroup}>Update Products</button>
          </div>
        </div>
      ) : (
        <div className="unauthorized-containers">
          <h1>Unauthorized</h1>
        </div>
      )}
    </>
  );
}
