import React, { useRef, useState } from "react";
import styles from "./auth.scss";
import { TiUserAddOutline } from "react-icons/ti";
import Card from "../../components/card/Card";
import { toast } from "react-toastify";
import { registerUser, validateEmail } from "../../services/authService";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SET_LOGIN, SET_NAME } from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";
import verify1 from "../../assets/home/verify1.svg";
import verify2 from "../../assets/home/verify2.svg";
import verify3 from "../../assets/home/verify3.svg";
import verify4 from "../../assets/home/verify4.svg";
import verify5 from "../../assets/home/verify5.svg";
import verify6 from "../../assets/home/verify6i.svg";
import backarrow from "../../assets/home/backarrow.svg";
import forwardarrow from "../../assets/home/forwardarrow.svg";
import "./signup.css";
import Personal from "./registerComponents/Personal";
import Business from "./registerComponents/Business";

const initialState = {
  businessName: "",
  businessEmail: "",
  ownerFirstName: "",
  ownerLastName: "",
  ownerEmail: "",
  ownerPassword: "",
  salesFirstName: "",
  salesLastName: "",
  salesEmail: "",
  industry: "",
  country: "",
  image: {},
  salesPassword: "",
};

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState(initialState);
  const [stage, setStage] = useState("personal");
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const fileInputRef = useRef(null);

  const {
    businessName,
    businessEmail,
    ownerFirstName,
    ownerLastName,
    ownerEmail,
    ownerPassword,
    salesFirstName,
    salesLastName,
    businessAddress,
    businessPhone,
    salesEmail,
    industry,
    country,
    salesPassword,
  } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log(file)

    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setProfileImage(e.target.files[0]);
      setformData({ ...formData, image: file });
    }
  };

  const handlePencilClick = () => {
    fileInputRef.current.click();
  };

  const register = async (e) => {
    e.preventDefault();

    if (
      !businessName ||
      !businessEmail ||
      !ownerFirstName ||
      !ownerLastName ||
      !ownerEmail ||
      !ownerPassword ||
      !salesFirstName ||
      !salesLastName ||
      !salesEmail ||
      !salesPassword
    ) {
      return toast.error("All fields are required");
    }
    if (ownerPassword.length < 6 || salesPassword.length < 6) {
      return toast.error("Passwords must be up to 6 characters");
    }
    if (!validateEmail(businessEmail)) {
      return toast.error("Please enter a valid email");
    }

    setIsLoading(true);
    try {
      let imageURL;
      if (
        profileImage &&
        (profileImage.type === "image/jpeg" ||
          profileImage.type === "image/jpg" ||
          profileImage.type === "image/png")
      ) {
        const image = new FormData();
        image.append("file", profileImage);
        image.append("cloud_name", "dfrwntkjm");
        image.append("upload_preset", "hqq7lql7");

        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dfrwntkjm/image/upload",
          { method: "post", body: image }
        );
        const imgData = await response.json();
        imageURL = imgData.url.toString();
      }

      const userData = {
        businessName,
        businessEmail,
        ownerFirstName,
        ownerLastName,
        ownerEmail,
        businessAddress,
        businessPhone,
        ownerPassword,
        salesFirstName,
        salesLastName,
        salesEmail,
        industry,
        country,
        photo: profileImage ? imageURL : "https://i.ibb.co/4pDNDk1/avatar.png",
        salesPassword,
      };

      const data = await registerUser(userData);
      await dispatch(SET_LOGIN(true));
      await dispatch(SET_NAME(data.businessName));
      navigate("/register/success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleNextStage = () => {
    if (stage === "personal") {
      // Check if personal stage fields are filled
      if (ownerFirstName && ownerLastName && ownerEmail && ownerPassword) {
        setStage("business");
      } else {
        toast.error("Please fill in all personal information.");
      }
    }
    if (stage === "business") {
      // Check if business stage fields are filled
      if (
        businessName &&
        businessEmail &&
        salesFirstName &&
        salesLastName &&
        salesEmail &&
        salesPassword
      ) {
        setStage("verification");
      } else {
        toast.error("Please fill in all business information.");
      }
    }
    if (stage === "verification") {
      setStage("submit");
    }
    // No need to check for verification stage, as it's the last stage
  };

  const handlePrevStage = () => {
    if (stage === "submit") {
      setStage("verification");
    }
    if (stage === "verification") {
      setStage("business");
    }
    if (stage === "business") {
      setStage("personal");
    }
  };

  const handleAgreement = (e) => {
    setIsAgreementChecked(e.target.checked);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="sign-up-businesss">
        <div className="sign-up-business-header">
          <h1>Setting up Your Business Account</h1>
          <p>Already created an account? <Link to="/login">Login</Link></p>
        </div>
        <div className="business-informations">
          <div className="sign-up-cards">
            <div className="sign-up-card">
              <div
                className={
                  stage === "personal"
                    ? "sign-up-card-icon active-icon-card"
                    : "sign-up-card-icon"
                }
              >
                <img src={verify1} alt="sign up" />
              </div>
              <span>Personal</span>
            </div>
            <div className="sign-up-lines"></div>
            <div className="sign-up-card">
              <div
                className={
                  stage === "business"
                    ? "sign-up-card-icon active-icon-card"
                    : "sign-up-card-icon"
                }
              >
                <img src={verify2} alt="sign up" />
              </div>
              <span>Business</span>
            </div>
            <div className="sign-up-lines"></div>
            <div className="sign-up-card">
              <div
                className={
                  stage === "verification"
                    ? "sign-up-card-icon active-icon-card"
                    : "sign-up-card-icon"
                }
              >
                <img src={verify4} alt="sign up" />
              </div>
              <span>Verification</span>
            </div>
          </div>
          <div className="company-logo">
            {stage !== "verification" && stage !== "submit" ? (
              <>
                <div className="company-logo-icon">
                  <img
                    src={selectedImage ? selectedImage : verify5}
                    alt="company logo"
                  />
                  <div
                    className="company-logo-icon-pencil"
                    onClick={handlePencilClick}
                  >
                    <img src={verify6} alt="company logo" />
                    {/* </div> */}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {stage !== "verification" && stage !== "submit" ? (
            <h6 className="center-text">Accepts: PNG, JPEG and JPG only</h6>
          ) : null}
        </div>

        <div className="sign-up-actions">
          <div className="sign-up-forms">
            <form
              onSubmit={(e) =>
                stage === "submit" ? register(e) : e.preventDefault()
              }
              method="post"
              enctype="multipart/form-data"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              {stage === "personal" && (
                <Personal
                  handleInputChange={handleInputChange}
                  ownerFirstName={ownerFirstName}
                  ownerLastName={ownerLastName}
                  ownerEmail={ownerEmail}
                  ownerPassword={ownerPassword}
                />
              )}

              {stage === "business" && (
                <Business
                  handleInputChange={handleInputChange}
                  businessName={businessName}
                  businessEmail={businessEmail}
                  businessAddress={businessAddress}
                  businessPhone={businessPhone}
                  salesFirstName={salesFirstName}
                  salesLastName={salesLastName}
                  industry={industry}
                  country={country}
                  salesEmail={salesEmail}
                  salesPassword={salesPassword}
                />
              )}

              {stage === "verification" && (
                <div className="verify-informations">
                  <div>
                    <h3>
                      Kindly verify all passwords and other login informations
                      before submiting this form.
                    </h3>
                    <div className="agreement_checkbox">
                      <input
                        onChange={(e) => handleAgreement(e)}
                        type="checkbox"
                        checked={isAgreementChecked}
                      />
                      <p>
                        I have read and agreed to GNLIFE Inventory's &nbsp;
                        <Link to="/terms-and-agreement">
                          Terms and conditions
                        </Link>{" "}
                        &nbsp; and &nbsp;
                        <Link to="/our-policy">privacy policy</Link>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {stage === "submit" && (
                <div className="verify-informations">
                  <div>
                    <h3>
                      Kindly verify all passwords and other login informations
                      before submiting this form.
                    </h3>
                    <div className="agreement_checkbox">
                      <input
                        onChange={(e) => handleAgreement(e)}
                        type="checkbox"
                        checked={isAgreementChecked}
                      />
                      <p>
                        I have read and agreed to GNLIFE Inventory's{" "}
                        <Link to="/terms-and-agreement">
                          Terms and conditions
                        </Link>
                        <Link to="/our-policy">privacy policy</Link> and{" "}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="sign-up-buttons">
                {stage === "personal" ? (
                  <button
                    onClick={handlePrevStage}
                    type="button"
                    className="go-back"
                    disabled
                  >
                    <img src={backarrow} alt="Go Back" />
                    Go Back
                  </button>
                ) : (
                  <button
                    onClick={handlePrevStage}
                    type="button"
                    className="go-back"
                  >
                    <img src={backarrow} alt="Go Back" />
                    Go Back
                  </button>
                )}

                {/* {
                  stage === "verification" && <button
                    onClick={handleNextStage}
                    type="submit"
                    className="proceed"
                  >
                    Submit <img src={forwardarrow} alt="proceed" />
                  </button>
                } */}

                <button
                  onClick={handleNextStage}
                  type={stage === "submit" ? "submit" : "button"}
                  className={`proceed ${
                    (stage === "verification" || stage === "submit") &&
                    isAgreementChecked === false
                      ? "disabled_bg"
                      : ""
                  }`}
                  disabled={
                    (stage === "verification" || stage === "submit") &&
                    isAgreementChecked === false
                      ? true
                      : false
                  }
                >
                  {stage === "verification" || stage === "submit"
                    ? "Submit"
                    : "Proceed"}
                  <img src={forwardarrow} alt="proceed" />
                </button>

                {/* {stage === "submit" ? (
                  <button
                    onClick={handleNextStage}
                    type="submit"
                    className="proceed"
                  >
                    Submit <img src={forwardarrow} alt="proceed" />
                  </button>
                ) : (
                  <button
                    onClick={handleNextStage}
                    type="button"
                    className="proceed"
                  >
                    Proceed <img src={forwardarrow} alt="proceed" />
                  </button>
                )} */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
