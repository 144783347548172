import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";
import { selectIsLoggedIn } from "../../../redux/features/auth/authSlice";
import { getSale } from "../../../redux/features/product/productSlice";
import Card from "../../card/Card";
import { SpinnerImg } from "../../loader/Loader";
import "./ProductDetail.scss";
import DOMPurify from "dompurify";

const SaleDetail = () => {
  useRedirectLoggedOutUser("/login");
  const dispatch = useDispatch();

  const { id } = useParams();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { sale, isLoading, isError, message } = useSelector(
    (state) => state.sale
  );

  const stockStatus = (quantity) => {
    if (quantity > 0) {
      return <span className="--color-success">In Stock</span>;
    }
    return <span className="--color-danger">Out Of Stock</span>;
  };

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getSale(id));
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  return (
    <div className="product-detail">
      <h3 className="--mt">Sale Detail</h3>
      <Card cardClass="card">
        {isLoading && <SpinnerImg />}
        {sale && (
          <div className="detail">
            <Card cardClass="group">
              {sale?.image ? (
                <img
                  src={sale.image.filePath}
                  alt={sale.image.fileName}
                />
              ) : (
                <p>No image set for this product</p>
              )}
            </Card>
            <h4>Product Availability: {stockStatus(sale.quantity)}</h4>
            <hr />
            <h4>
              <span className="badge">Name: </span> &nbsp; {sale.name}
            </h4>
            <p>
              {/* <b>&rarr; SKU : </b> {sale.sku} */}
            </p>
            <p>
              <b>&rarr; Category : </b> {sale.category}
            </p>
            <p>
              <b>&rarr; Price : </b> {"#"}
              {sale.price}
            </p>
            <p>
              <b>&rarr; soldAt : </b> {"#"}
              {sale.soldAt}
            </p>
            <p>
              <b>&rarr; Quantity sold : </b> {sale.quantity}
            </p>
            <p>
              <b>&rarr; Total Value : </b> {"#"}
              {sale.soldAt * sale.quantity}
            </p>
            <p>
              <b>&rarr; Profit : </b> {"#"}
              {(sale.soldAt * sale.quantity) - (sale.price * sale.quantity)}
            </p>
            <hr />
            {/* <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(sale.description),
              }}
            ></div> */}
            <hr />
            <code className="--color-dark">
              Sold on: {sale.createdAt.toLocaleString("en-US")}
            </code>
          </div>
        )}
      </Card>
    </div>
  );
};

export default SaleDetail;
