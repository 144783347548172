import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductList from "../../components/product/productList/ProductList";
import ProductSummary from "../../components/product/productSummary/ProductSummary";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import {
  selectIsLoggedIn,
  selectLoggedInBusinessOwner,
} from "../../redux/features/auth/authSlice";
import {
  getProducts,
  getSales,
  getOutOfStock,
} from "../../redux/features/product/productSlice";
// import {  } from "../../redux/features/product/salesSlice";
// import SalesList from "../../components/product/salesList/salesList";
// import OutOfStockList from "../../components/product/outOfStockList/outOfStockList";
// import NewSalesList from "../../components/product/salesList/NewSalesList";
import { useParams } from "react-router-dom";
// import { getCheckouts } from "../../redux/features/cart/cartSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const admin = useSelector(selectLoggedInBusinessOwner);

  const { id } = useParams();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { products, isLoading, isError, message } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getProducts());
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  return (
    <div>
      <ProductSummary products={products} admin={admin} />
    </div>
  );
};

export default Dashboard;
