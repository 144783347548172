import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import ProductForm from "../../components/product/productForm/ProductForm";
import {
  createProduct,
  selectIsLoading,
} from "../../redux/features/product/productSlice";
import { toast } from "react-toastify";

const initialState = {
  name: "",
  category: "",
  quantity: "",
  warehouse: "",
  price: "",
  cost: ""
};

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState(initialState);
  const [productImage, setProductImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [description, setDescription] = useState("");

  const isLoading = useSelector(selectIsLoading);

  const { name, category, price, quantity, cost, warehouse } = product;

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setProduct({ ...product, [name]: value });
  // };

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === "price" || name === "cost") {
      if (isNaN(value) || value < 0) {
        toast.error("Only numbers are allowed.")
        value = "";
        return;
      }
    }
    setProduct({ ...product, [name]: value });
  };

  const handleImageChange = (e) => {
    setProductImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const generateKSKU = (category) => {
    const letter = category.slice(0, 3).toUpperCase();
    const number = Date.now();
    const sku = letter + "-" + number;
    return sku;
  }; 

  const saveProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("sku", generateKSKU(category));
    formData.append("category", category);
    formData.append("quantity", Number(quantity));
    formData.append("warehouse", warehouse)
    formData.append("price", price);
    formData.append("cost", cost);
    formData.append("description", description);

    // console.log(...formData);

    await dispatch(createProduct(formData));
    navigate("/inventory");
  };
 
  return (
    <div>
      {isLoading && <Loader />}
      <ProductForm
        product={product}
        description={description}
        setDescription={setDescription}
        handleInputChange={handleInputChange}
        saveProduct={saveProduct}
      />
    </div>
  );
};

export default AddProduct;
