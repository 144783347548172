import axios from "axios";
import { saveAs } from "file-saver";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `/api/cart/`;

// Get all products
const getCart = async () => {
  const response = await axios.get(`${API_URL}/getcart`);
  return response.data;
};

// Add product to cart
const addToCart = async (id, formData) => {
  const response = await axios.post(`${API_URL}/add-to-cart/${id}`, formData);
  return response.data
}

const setPrice = async (id, cartId, price) => {
  const response = await axios.post(`${API_URL}set-price`, {
    id,
    cartId,
    price
  });
  return response.data;
};

const setCartQuantity = async (id, cartId, quantity) => {
  const response = await axios.post(`${API_URL}set-quantity`, {
    id,
    cartId,
    quantity,
  });
  return response.data;
};


// increase cart items
const increaseCartItems = async (id, cartId, quantity) => {
  const response = await axios.post(`${API_URL}increase`, {
    id,
    cartId,
    quantity,
  });
  return response.data;
};

// decrease cart items
const decreaseCartItems = async (id, cartId, quantity) => {
  const response = await axios.post(`${API_URL}decrease`, {
    id,
    cartId,
    quantity,
  });
  return response.data;
};

// Delete a Cart Item
const deleteCartItem = async (id) => {
  const response = await axios.delete(`${API_URL}delete-cart-item/${id}`);
  return response.data;
};

// check out cart
const checkoutCart = async (formData) => { 
  const response = await axios.post(`${API_URL}checkout`, formData);
  return response.data;
};

// get checkouts 
const getCheckouts = async (start, end) => {
  // console.log(start, end)
  const response = await axios.get(`${API_URL}getcheckouts?start=${start}&end=${end}`);
  return response.data;
};

// generate receipt
const generateReceipt = async (id) => {
  const response = await axios.get(`${API_URL}download-receipt/${id}`, { responseType: "blob" });
  return response.data;
};

// send receipt to whatsapp
const sendReceipt = async (id) => {
  const response = await axios.post(`${API_URL}send-receipt/${id}`);
  return response.data;
};

// send receipt to printer
const printReceipt = async (id) => {
  const response = await axios.get(`${API_URL}print-receipt/${id}`);
  return response.data;
};

const returnedGoods = async (id, formData) => {
  const response = await axios.post(`${API_URL}returned-goods/${id}`, formData);
  return response.data
}

const getCustomers = async () => {
  const response = await axios.get(`${API_URL}get-customers`);
  return response.data
}

const getIncompletePayments = async () => {
  const response = await axios.get(`${API_URL}get-incomplete-payments`);
  return response.data
}

const updatePayment = async (formData) => {
  const response = await axios.post(`${API_URL}update-incomplete-payment`, formData);
  return response.data
}


const cartService = {
  getCart,
  setPrice,
  setCartQuantity,
  increaseCartItems,
  decreaseCartItems,
  checkoutCart,
  getCheckouts,
  generateReceipt,
  deleteCartItem,
  addToCart,
  printReceipt,
  sendReceipt,
  returnedGoods,
  getCustomers,
  getIncompletePayments,
  updatePayment
};

export default cartService;
