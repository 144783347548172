import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/features/auth/authSlice";
import productReducer from "../redux/features/product/productSlice";
import filterReducer from "../redux/features/product/filterSlice";
import cartReducer from "./features/cart/cartSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    product: productReducer,
    sale: productReducer,
    allProductGroups: productReducer, 
    productsOutOfStock: productReducer,
    carts: cartReducer,
    checkouts: cartReducer,
    salesByYear: productReducer,
    topProducts: productReducer,
    lowProducts: productReducer,
    cart: cartReducer,
    sales: productReducer,
    filter: filterReducer,
  },
});
