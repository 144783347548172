import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "../productList/productList.scss";
import { AiOutlineEye } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Search from "../../search/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_OUT_OF_STOCK_PRODUCTS,
  selectFilteredProductsOutOfStock,
} from "../../../redux/features/product/filterSlice";
import {
  deleteProduct,
  getProducts,
  getOutOfStock,
  getSales,
} from "../../../redux/features/product/productSlice";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import deleteIcon from "../../../assets/home/delete-icon.svg";
import editIcon from "../../../assets/home/edit-icon.svg";
import {
  selectLoggedInBusinessOwner,
  selectLoggedInSales,
} from "../../../redux/features/auth/authSlice";
import InventoryHeader from "../../inventoryHeader/InventoryHeader";

const OutOfStockList = ({
  productsOutOfStock,
  isLoading,
  admin,
  activeRoute,
}) => {
  const [search, setSearch] = useState("");
  // const filteredProducts = useSelector(selectFilteredPoducts);
  const filteredOutOfStockProducts = useSelector(
    selectFilteredProductsOutOfStock
  );

  const dispatch = useDispatch();

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  // dispatch(getOutOfStock());
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredOutOfStockProducts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredOutOfStockProducts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredOutOfStockProducts]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredOutOfStockProducts.length;
    setItemOffset(newOffset);
  };
  //   End Pagination

  const delProduct = async (id) => {
    console.log(id);
    await dispatch(deleteProduct(id));
    await dispatch(getOutOfStock());
  };

  useEffect(() => {
    dispatch(FILTER_OUT_OF_STOCK_PRODUCTS({ productsOutOfStock, search }));
  }, [productsOutOfStock, search, dispatch]);

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div className="product-list">
      <div className="table">
        <InventoryHeader
          search={search}
          handleSearchChange={(e) => setSearch(e.target.value)}
        />

        {isLoading && <SpinnerImg />}

        <div className="table">
          {!isLoading && productsOutOfStock.length === 0 ? (
            <p className="no-products-p">-- Out of stock list</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Selling Price</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((productOutOfStock, index) => {
                  const { _id, price, quantity, name, category } =
                    productOutOfStock;
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{shortenText(name, 16)}</td>
                      <td>{category}</td>
                      <td>
                        {"#"}
                        {price}
                      </td>

                      <td className="icons">
                        {admin && (
                          <span>
                            <Link to={`/edit-product/${_id}`}>
                              {/* <FaEdit size={20} color={"green"} /> */}
                              <img src={editIcon} alt="edit" />
                            </Link>
                          </span>
                        )}

                        {admin && (
                          <span>
                            <img
                              onClick={() => confirmDelete(_id)}
                              src={deleteIcon}
                              alt="delete"
                            />
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePageClass"
        />
      </div>
    </div>
  );
};

export default OutOfStockList;
