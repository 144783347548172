import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { getSalesByYear } from "../../../redux/features/product/productSlice";

const OurChart = ({ sales }) => {
  const dispatch = useDispatch();
  const [salesData, setSalesData] = useState([]);
  const [profitData, setProfitData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);

  useEffect(() => {
    dispatch(getSalesByYear("2024"));
  }, [dispatch]);

  const monthsIds = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    if (sales && sales.data) {
      // console.log("Sales data received:", sales.data); // Add this line to log the sales data
      const newSalesData = [];
      const newProfitData = [];
      sales.data.forEach((data) => {
        newSalesData.push(data.totalSales);
        newProfitData.push(data.totalProfit);
      });
      setSalesData(newSalesData);
      setProfitData(newProfitData);
      
      const newSelectedMonth = monthsIds.slice(
        sales?.data[0]?._id.month - 1,
        sales?.data[sales.data.length - 1]?._id.month
      );
      setSelectedMonth(newSelectedMonth);
    }
  }, [sales]);
  

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const state = {
    options: {
      colors: ["#79D0F1", "#57DA65"],
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: selectedMonth.length > 0 ? selectedMonth : [],
      },
      fill: {
        colors: ["#79D0F1", "#57DA65"],
        opacity: 1,
        type: ["#79D0F1", "#57DA65"],
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: ["#817AF3", "#74B0FA", "#79D0F1"],
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatter(val);
          },
        },
      },
    },
    series: [
      {
        name: "Sales",
        data: salesData,
      },
      {
        name: "Profit",
        data: profitData,
      },
    ],
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        height="100%"
        width="100%"
      />
    </div>
  );
};

export default OurChart;
