import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `/api/products/`;

// Create New Product
const createProduct = async (formData) => {
  const response = await axios.post(API_URL, formData);
  return response.data;
};

const createMultipleProducts = async (formData) => {
  const response = await axios.post(`${API_URL}multiple`, formData);
  return response.data;
};

// Get all products
const getProducts = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const getProductGroups = async () => {
  const response = await axios.get(`${API_URL}product-group`);
  return response.data;
};

// Delete a Product
const deleteProduct = async (id) => {
  const response = await axios.delete(API_URL + id);
  return response.data;
};

// delete product group
const deleteGroup = async (id) => {
  const response = await axios.delete(`${API_URL}group/` + id);
  return response.data;
}

// Get a Product
const getProduct = async (id) => {
  const response = await axios.get(API_URL + id);
  return response.data;
};

// Update Product
const updateProduct = async (id, formData) => {
  console.log("Closest to it", formData, id)
  const response = await axios.patch(`${API_URL}${id}`, formData);
  return response.data;
};

const updateProductGroup = async (id, formData) => {
  const response = await axios.patch(`${API_URL}update-product-group/${id}`, formData);
  return response.data;
};

// Sell a product
const sellProduct = async (id, formData) => {
  const response = await axios.post(`${API_URL}sellproduct/${id}`, formData);
  return response.data
}

// Get all Sales
const getSales = async (interval) => {
  const response = await axios.get(`${API_URL}getsales?query=${interval}`);
  return response.data;
};

// Get all Sales
const getSale = async (id) => {
  const response = await axios.get(`${API_URL}getsale/${id}`);
  return response.data;
};

// Get products that are out of stock
const getOutOfStock = async (id) => {
  const response = await axios.get(`${API_URL}outofstock`);
  return response.data;
};

const getTopProducts = async () => {
  const response = await axios.get(`${API_URL}top-products`);
  return response.data;
}

const getLowProducts = async () => {
  const response = await axios.get(`${API_URL}low-products`);
  return response.data;
}

const getSalesByYear = async (id) => {
  const response = await axios.get(`${API_URL}sales-by-year/${id}`);
  return response.data;
}



const productService = {
  createProduct,
  getProducts,
  getProductGroups,
  createMultipleProducts,
  updateProductGroup,
  getProduct,
  deleteGroup,
  getTopProducts,
  getSalesByYear,
  getLowProducts,
  deleteProduct,
  updateProduct,
  sellProduct,
  getSales,
  getSale,
  getOutOfStock
};

export default productService;
